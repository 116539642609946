<template>
  <div class="wallet">
    <rxNavBar title="钱包" androidOrIOSFlag="true"></rxNavBar>
    <div class="wallet_text">
      <a class="wallet_detail" @click="walletRecordClick">零钱明细</a>
      <img class="wallet_img" src="../../../assets/images/wallet2x.png"/>
      <div class="wallet_money">
        <p>
          <img class="withdrawMoney_img" :src="require('../../../assets/images/money2x.png')"/>
          <span class="wallet_money_number">{{ balance == null || balance < 0 ? '0.00' : balance }}</span>
        </p>
      </div>
      <div class="myMoney">我的账户余额</div>
      <van-button class="withdrawMoney_button" @click="applyWithDrawClick">申请提现</van-button>
      <span class="lookMoney_plan" @click="withdrawalPlanClick">查看提现进度</span>
    </div>

  </div>
</template>

<script>
import {NavBar, Icon, Button, NumberKeyboard} from 'vant';
import {userMoneyInfo, userCanApplyMoneyInfo, queryPersonDetails} from "../../../getData/getData";
import {getStaffId, globaluserId, responseUtil} from "../../../libs/rongxunUtil";
import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";

export default {
  components: {
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,
    [Button.name]: Button,
    [NumberKeyboard.name]: NumberKeyboard,
    rxNavBar
  },
  data() {
    return {
      balance: '',
      isBankCode: 0
    }
  },
  name: "wallet",
  mounted() {
    this.init()
  },
  methods: {
    leftReturn() {
      this.$router.go(-1);
    },
    init() {
      let that = this
      let initData = {}
      initData.user_id = globaluserId()
      queryPersonDetails(initData).then((response) => {
        responseUtil.dealResponse(that, response, () => {
          console.log(response)
          that.isBankCode = response.data.data.isBankCode
        })
      })
      userCanApplyMoneyInfo(initData).then((response) => {
        console.log(response)
        let applyingMoney = response.data.data.totalMoney
        userMoneyInfo(initData).then(function (response) {
          responseUtil.dealResponse(that, response, () => {
            console.log(response)
            that.balance = response.data.data.userMoney - applyingMoney
          })
        })
      })

    },
    // 跳转零钱明细
    walletRecordClick: function () {
      this.$router.push({
        name: 'myWallet',
      })
    },
    // 跳转申请提现
    applyWithDrawClick: function () {
      let that = this;
      if (1 != that.isBankCode){
        responseUtil.alertErrMsg(that, '请前往设置绑定银行卡')
        return
      }else if(0 >= that.balance){
        responseUtil.alertErrMsg(that, '账户余额为0，无法申请提现')
        return
      }else{
        this.$router.push({
          name: 'applyWithdrawal',
          params: {
            //将用户余额传到申请提现页面，用于与提现金额作比较
            balance: that.balance
          }
        })
      }
    },
    // 查看提现进度
    withdrawalPlanClick: function () {
      let that = this;
      this.$router.push({
        name: 'withdrawalPlan',
        params: {
          // balance: that.balance
        }
      })
    },

  }

}
</script>

<style scoped>
.wallet_text {
  width: 345px;
  height: 514px;
  background-color: rgba(255, 255, 255, 1);
  margin: 0px 15px ;
  border-radius: 8px;
  padding-top: 10px;
}

.wallet_detail {
  width: 60px;
  height: 21px;
  color: rgba(34, 34, 34, 1);
  font-size: 15px;
  text-align: left;
  text-decoration: underline;
  font-family: PingFangSC-Regular;
  margin-left: 264px;
}

.wallet_img {
  /*padding-left: 149px;*/
  /*padding-top: 69px;*/
  width: 48px;
  height: 39.33px;
  margin-top: 70px;
  margin-left: 150px;
}

.wallet_money {
  display: flex;
  padding-top: 10px;
  width: 100%;
  height: 43px;
}

.wallet_money p {
  color: red;
  text-align: center;
  margin: 0 auto;
  width: 200px;
  font-size: 35px;
}

.withdrawMoney_img {
  width: 16px;
}

.wallet_money_number {
  color: rgba(255, 93, 59, 1);
  height: 47px;
  font-size: 35px;
  text-align: left;
  letter-spacing: -1px;
  font-family: Oswald-Regular;
  padding-left: 10px;
}

.myMoney {
  width: 100%;
  text-align: center;
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
  font-family: PingFangSC-Regular;
}

.withdrawMoney_button {
  margin: 128px 40px 0px 40px;
  width: 265px;
  height: 45px;
  border-radius: 8px;
  background-color: #FFFFFF;
}

.lookMoney {


}

.lookMoney_plan {
  margin: 19px 0px 65px 137px;
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
  font-family: PingFangSC-Regular;
}

</style>
